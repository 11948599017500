import { Table } from "@medusajs/ui";
import { GoEye } from "react-icons/go";
import getDate from "../../../../core/utils/get-date";
import { User } from "../../../../core/types";
import { FiSend } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

export const MerchantTable = ({
  items,
  open,
  isKyb,
}: {
  open?: any;
  items: any;
  isKyb?: boolean;
}) => {
  const navigate = useNavigate();
  const getMerchantProgress = ({ merchant }: { merchant: any }): number => {
    let result = 10;
    if (merchant.mqr != null) {
      result += 22;
    }
    if (merchant.files.length > 0) {
      result += 22;
    }
    if (merchant.offer) {
      result += 22;
    }
    return result;
  };

  return (
    <Table className="">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">
            Merchant
          </Table.HeaderCell>
          {isKyb ? (
            <>
              <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">
                Email
              </Table.HeaderCell>
              <Table.HeaderCell className="  whitespace-nowrap font-normal text-neutral-500 border border-neutral-200 text-left px-3">
                Company name
              </Table.HeaderCell>
            </>
          ) : (
            <>
              <Table.HeaderCell className="  whitespace-nowrap font-normal text-neutral-500 border border-neutral-200 text-left px-3">
                Company name
              </Table.HeaderCell>
              <Table.HeaderCell className=" whitespace-nowrap font-normal text-neutral-500 border border-neutral-200 text-left px-3">
                Created on
              </Table.HeaderCell>
            </>
          )}

          {/* <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">Date</Table.HeaderCell> */}
          <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">
            Status
          </Table.HeaderCell>
          {isKyb ? null : (
            <>
              <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">
                Progress
              </Table.HeaderCell>
            </>
          )}
          <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3"></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body className=" ">
        {items &&
          items.map((merchant: any) =>
            isKyb ? (
              merchant.files.length > 0 && (
                <Table.Row key={merchant.id}>
                  <Table.Cell className=" whitespace-nowrap py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                    {merchant.name}
                  </Table.Cell>
                  <Table.Cell className=" py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                    {merchant.email}
                  </Table.Cell>
                  <Table.Cell className=" py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                    {merchant?.mqr?.companyName}
                  </Table.Cell>
                  <Table.Cell className=" py-3 px-3 text-center border border-neutral-200 text-neutral-900 text-sm">
                    <span
                      className={` ${
                        merchant.kyc ? " bg-green-600" : "bg-secondary-600"
                      } capitalize py-[2px] px-[10px] font-[600] rounded-full text-white text-xs `}
                    >
                      {merchant.kyc ? "Approved" : "Pending"}
                    </span>
                  </Table.Cell>

                  <Table.Cell className=" text-center py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                    <button
                      className=" text-primary-500 text-sm flex gap-x-4 items-center"
                      onClick={() =>
                        navigate("documents", {
                          state: { merchant: merchant },
                        })
                      }
                    >
                      <GoEye size={15} />
                      View
                    </button>
                  </Table.Cell>
                </Table.Row>
              )
            ) : (
              <Table.Row key={merchant.id}>
                <Table.Cell className=" whitespace-nowrap py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                  {merchant.name}
                </Table.Cell>
                <Table.Cell className=" py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                  {merchant?.mqr?.companyName}
                </Table.Cell>
                <Table.Cell className=" py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                  {getDate(merchant.created_at)}
                </Table.Cell>

                <Table.Cell className=" py-3 px-3 text-center border border-neutral-200 text-neutral-900 text-sm">
                  <span
                    className={` ${
                      merchant.email === "approved"
                        ? " bg-green-600"
                        : "bg-secondary-600"
                    } capitalize py-[2px] px-[10px] font-[600] rounded-full text-white text-xs `}
                  >
                    {"Pending"}
                  </span>
                </Table.Cell>

                <Table.Cell className=" py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                  <div className="relative h-3 bg-grey-0 overflow-hidden rounded-full w-full">
                    <div
                      className="absolute bg-primary-500 h-full left-0 top-0 "
                      style={{
                        width: getMerchantProgress({ merchant }) + "%",
                      }}
                    ></div>
                  </div>
                </Table.Cell>
                <Table.Cell className=" text-center py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                  <button
                    className="flex mx-auto gap-x-2 items-center"
                    onClick={() => open(merchant)}
                  >
                    <GoEye size={15} className=" text-neutral-900" /> View
                  </button>
                </Table.Cell>
              </Table.Row>
            )
          )}
      </Table.Body>
    </Table>
  );
};
