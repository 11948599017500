import { Calendar } from "@medusajs/ui";

export const CustomCalendar = ({
  date,
  setOpenCalendar,
  pickDate,
  setDate,
}: any) => {
  return (
    <div className="fixed bg-black/20 h-full w-full flex items-center justify-center left-0 top-0 z-10">
      <button
        onClick={() => setOpenCalendar(false)}
        className="absolute h-full w-full"
      ></button>
      <div className="calandar-parent relative bg-white p-3 rounded shadow-lg shadow-black/40">
        <Calendar
          className=" min-w-[300px] flex justify-center"
          selected={date}
          onSelect={(date: any) => pickDate(date)}
        />
        <div className="mt-3 flex justify-end">
          <button
            onClick={() => {
              setDate(undefined);
              setOpenCalendar(false);
            }}
            className=" outline-none text-white text-sm rounded bg-primary-500 px-4 py-1"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};
